import React, { Component } from 'react';
import { BrowserRouter as Router, Route,  Link, Switch} from 'react-router-dom';
import smLogo from './images/smallbrand.png';
import jcLogo from './images/mybrandblk.svg';
import './output.css';

import Home from './home.jsx';
import About from './about.jsx'
import Products from './products.jsx';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
        <Switch>
       <Route exact path='/' component={Home}/>
       <Route path ='/About' component={About}/>
       <Route path ='/Products'  component={Products}/>
       </Switch>
       <nav class="navbar fixed-top navbar-expand-sm navbar-light bg-transparent">
       <a class="navbar-brand " href="/"><img src={smLogo} class="navbrand" alt=""/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-angle-double-down"></i>
        </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    <li class="nav-item">
    <Link className="nav-link" to={'/About'}>About RANDALL</Link>
      </li>
      <li class="nav-item">
      <Link className="nav-link" to={'/Products'}>PRODUCTS</Link>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://squareup.com/appointments/book/4QVDTY1GE6DH0/randall-liger-charleston-sc">MAKE APPOINTMENT</a>
      </li>
      </ul>
      </div>
      
      </nav>
      <footer class="fixed-bottom text-center"><a href="http://www.seejustin.co"><img src={jcLogo} class="footbrand" alt=""/></a></footer>
      </div>
      </Router>
      


    );
  }
}

export default App;
