import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import bumble from './images/Bumble_and_Bumble.png';
import deva from  './images/devacurl_logo1.png';
import keratin from './images/keratin-complex.png';
import redken from './images/Redken.png';

import './output.css';

class Products extends Component {
  render() {
    return (
      <Router>
        <div class="container">
          <Fade>
              <h1>Products</h1>
              <div class="row">
                <div class="col-sm-4 one"><img src={bumble} class="prodimg" alt=""/></div>
                <div class="col-sm-4"><img src={deva} class="prodimg" alt=""/></div>
                <div class="col-sm-4"><img src={keratin} class="prodimg" alt=""/></div>
                <div class="col-sm-4"><img src={redken} class="prodimg" alt=""/></div>
            </div>
                {/* <div class="container">
  <div class="row">
    <div class="col"><img src={bumble} class="prodimg" alt=""/></div>
    <div class="col"><img src={deva} class="prodimg" alt=""/></div>
    <div class="w-100"></div>
    <div class="col"><img src={keratin} class="prodimg" alt=""/></div>
    <div class="col"><img src={redken} class="prodimg" alt=""/></div>
  </div>
</div> */}

            
        </Fade>
        </div>
     </Router>
    );
  }
}
export default Products; 









