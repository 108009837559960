import React, { Component } from 'react';
import mainLogo from'./images/centerlogo.svg';
import './output.css';
import Fade from 'react-reveal/Fade';



class Home extends Component {
  render() {
    return (
        <div>
            <Fade>
            <div className="container-fluid">
                <img src={mainLogo} class="logo" alt=""/>
                <br/>
                <h4>Master Color Specialist & Precision Haircutter</h4>
                <br/>
                <div class ="centerbtn">
                <a href="https://squareup.com/appointments/book/4QVDTY1GE6DH0/randall-liger-charleston-sc"><button type="button" class="btn btn-primary btn-lg outline">MAKE AN APPOINTMENT</button></a>
                </div>
            </div>
            </Fade>

           
        </div>
    )
        
  }
};

export default Home;