import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import rdall from './images/rl.png';


import './output.css';

class About extends Component {
  render() {
    return (
      <Router>
        <div class="container">
          <Fade>
          <img src={rdall} className="face" alt=""/>
            <div class="abttext">
                <h1>About Randall</h1>
                    <p class="bio">Randall has over 36 years of experience in the hair industry.
                            He has worked as a salon owner, master stylist, colorist, & educator for global product companies.

                            Randall understands that hair is the personal accessory that speaks volumes about you, so the cut and color is only the start of a client's hair journey. His wide breadth of knowledge and precision styling goes into crafting a hairstyle that both accentuates
                            & compliments the client's natural allure.
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                    </p>

            </div>
        </Fade>
        </div>
     </Router>
    );
  }
}
export default About;           
